import { useOktaAuth } from '@okta/okta-react';
import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import 'semantic-ui-css/components/button.css';
import { SCHEDULE_PAGE } from "../../routes";
import "./Home.css";

const Home = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    if (!authState?.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      oktaAuth.getUser().then((info) => {
        setUserInfo(info);
      }).catch((err) => {
        console.error(err);
      });
    }
  }, [authState, oktaAuth]);

  const login = async () => {
    oktaAuth.signInWithRedirect({ originalUri: '/' });
  };

  if (!authState) {
    return null;
  }

  return (
    <div className='login__okta'>
      {authState.isAuthenticated && userInfo
        && (
          <Navigate to={SCHEDULE_PAGE} />
        )}

      {!authState.isAuthenticated
        && (
          <Button id="login-button" class="large ui button primary" onClick={login}>Login Via Okta</Button>
        )}
    </div>
  );
};
export default Home;
