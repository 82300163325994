import axios from "axios"
import { API_HOST } from '../../constants';

const OKTA_TOKEN_STORAGE = "okta-token-storage"

const api = axios.create({
  baseURL: `${API_HOST}/v1`,
  timeout: 3000,
})

const GetAuth = () => {
  let token = localStorage.getItem(OKTA_TOKEN_STORAGE);
  token = JSON.parse(token);
  if (!token) {
    return {};
  }
  return token;
}

const errorHandler = (error) => {
  const statusCode = error.response?.status

  if (statusCode && statusCode >= 400 && statusCode <= 500) {
    console.error(error.response.data)
    alert(error.response.data?.error)
  }
  return Promise.reject(error)
}

api.interceptors.request.use(
  config => {
    let { accessToken, idToken } = GetAuth();
    config.headers["Email"] = idToken?.claims?.email;
    config.headers["Authorization"] = "Bearer " + accessToken?.accessToken;
    return config;
  },
  error => {
    Promise.reject(error);
  }
);
api.interceptors.response.use(undefined, (error) => {
  return errorHandler(error)
})

export default api