import moment from 'moment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export default function CustomDatePicker({ disabled, label, value, className, onChange }) {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        disabled={disabled}
        label={label}
        value={moment(value)}
        onChange={onChange}
        format="YYYY-MM-DD"
        className={className}
      />
    </LocalizationProvider>
  );
}