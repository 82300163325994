// @mui material components
import { useState } from "react";
import {
  TextField,
  MenuItem,
  Grid,
  Card
} from '@mui/material';

import MDInput from "../../components/MDInput";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDButton from "../../components/MDButton";
import CustomDatePicker from "../../components/DatePicker";
import { STATUSALL, statuses } from "../../constants/hireStatus";

import DashboardLayout from "../../views/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../views/Navbars/DashboardNavbar";
import DataTable from "../../views/Tables/DataTable";
import "./EmployeeLayout.css"


const EmployeeLayout = ({ isHiddenStatus, onFilterChange, onPageChange, data, columns, children }) => {
  const [inputValue, setInputValue] = useState('');
  const [filterData, setFilter] = useState({
    date_filter: new Date().toLocaleDateString('en-CA'),
    status_filter: null,
    employee_id_filter: null,
  })

  const handleSetFilter = (key, value) => {
    const newVal = { ...filterData, [key]: value };
    setFilter(newVal);
    onFilterChange(newVal);
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value ? event.target.value : null);
  };

  const handleSearchClick = () => {
    handleSetFilter('employee_id_filter', inputValue)
  };
  return (
    <DashboardLayout>
      <DashboardNavbar isMini={true} />
      <MDBox
        display="flex"
        justifyContent='flex-start'
        gap={1}
      >
        <CustomDatePicker
          id="date_filter"
          value={filterData.date_filter}
          label="Date"
          onChange={(c) => { handleSetFilter("date_filter", c.format("YYYY-MM-DD")) }}
        />
        {
          !isHiddenStatus ?
            <TextField
              select
              id="status_filter"
              value={filterData.status_filter ?? STATUSALL}
              label="Status"
              onChange={(c) => { handleSetFilter("status_filter", c.target.value == STATUSALL ? null : c.target.value) }}
              hidden
            >
              {statuses.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            : null
        }
        <MDBox
          id="employee_id_block"
          display="flex"
          gap={1}
        >
          <MDInput
            id="employee_id_filter"
            label="Search Employee ID"
            value={inputValue ?? ''}
            onChange={handleInputChange}
          />
          <MDButton
            variant="gradient"
            color="dark"
            onClick={handleSearchClick}
          >
            Search
          </MDButton>
        </MDBox>
      </MDBox>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                pt={2}
                pb={2}
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDTypography variant="h6" color="white">
                  Employee
                </MDTypography>
                {children}
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows: data?.data ?? [], page: data.page, pageCount: data.totalPage }}
                  onPageChange={onPageChange}
                  isSorted={false}
                  pagination={{ variant: "gradient" }}
                  entriesPerPage={false}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

    </DashboardLayout >
  );
};

export default EmployeeLayout;
