import moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { renderDigitalClockTimeView } from '@mui/x-date-pickers/timeViewRenderers';

export default function CustomDateTimePicker({ disabled, label, value, className, onChange }) {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DateTimePicker
        disabled={disabled}
        label={label}
        value={moment(value)}
        onChange={onChange}
        className={className}
        format='YYYY-MM-DD HH:mm:ss'
        viewRenderers={{
          hours: renderDigitalClockTimeView,
          minutes: renderDigitalClockTimeView,
          seconds: renderDigitalClockTimeView,
        }}
      />
    </LocalizationProvider>
  );
}