const types = [
  {
    label: 'FT',
    value: 1,
  },
  {
    label: 'PT',
    value: 2,
  },
];

const typesObj = {}
types.forEach((type) => {
  typesObj[type.value] = type.label
})
export { types, typesObj }