import moment from "moment";
import { useState, useEffect } from "react";
import {
  Button,
  TextField,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material"
import CustomDatePicker from "../../../components/DatePicker";
import CustomDateTimePicker from "../../../components/DateTimePicker";
import ConfirmationDialog from "../../../components/ConfirmationDialog";
import { employeeAPI } from "../../../apis/employeeAPI";
import "./UnpaidLeaveDialog.css";


const EDIT = "Edit"
const NEW = "New"
const DETAIL = "Detail"

function UnpaidLeaveDialog({
  disabled,
  button: DialogButton,
  style,
  item,
  title,
  renderRows,
  userInfo,
  generalSettings,
}) {
  const initialState = {
    employeeNumber: '',
    region: 'TW',
    name: '',
    nativeName: '',
    email: '',
    suspendStartAt: moment().format('YYYY-MM-DD'),
    suspendEndAt: moment().format('YYYY-MM-DD'),
    returnDeviceAt: moment().format('YYYY-MM-DD HH:mm:ss'),
    hrEmail: '',
  };

  const [errors, setErrors] = useState({});
  const [open, setOpen] = useState(false);
  const [openConfirm, setConfirm] = useState(false);
  const [inputData, setInputData] = useState(item ?? initialState);
  const [isDetail,] = useState(title === DETAIL);

  useEffect(() => {
    renderDialog();
  }, [item])

  const handleClickOpen = () => {
    setOpen(true);
    renderDialog();
  }
  const handleClose = () => setOpen(false);
  const handleInputChange = (e) => {
    setInputData({ ...inputData, [e.target.name]: e.target.value, hrEmail: userInfo?.email });
  }
  const validateValues = (inputValues) => {
    let errors = {};
    if (!inputValues.employeeNumber) {
      errors.employeeNumber = "Employee ID is required";
    }
    if (!inputValues.region) {
      errors.region = "Region is required";
    }
    if (!inputValues.name) {
      errors.name = "Name is required";
    }
    if (!inputValues.nativeName) {
      errors.name = "Name is required";
    }
    if (!inputValues.email) {
      errors.email = "Email is required";
    }
    if (inputValues.email && !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(inputValues.email)) {
      errors.email = "This is the wrong pattern";
    }
    if (!inputValues.returnDeviceAt) {
      errors.returnDeviceAt = "Return Device DateTime is required";
    }
    if (!inputValues.suspendStartAt) {
      errors.suspendStartAt = "Suspend Start Date is required";
    }
    if (!inputValues.suspendEndAt) {
      errors.suspendEndAt = "Suspend End Date is required";
    }
    if (inputValues.suspendEndAt <= inputValues.suspendStartAt) {
      errors.suspendEndAt = "Suspend End Date must be greater than Suspend Start Date";
    }
    if (!inputValues.hrEmail) {
      errors.hrEmail = "HR Email not found";
    }
    return errors;
  }
  const handleValidation = (e) => {
    e.preventDefault();
    let errors = validateValues(inputData);
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      setConfirm(true);
    }
  }
  const handleSubmit = () => {
    switch (title) {
      case NEW:
        employeeAPI.createUnpaidLeave(inputData)
          .then(({ id }) => {
            renderRows();
          })
        break
      case EDIT:
        employeeAPI.updateUnpaidLeave(inputData.id, inputData)
          .then(() => {
            renderRows();
          })
        break
      default:
        console.log("title not found");
    }
    setInputData(initialState);
    handleClose()
  }
  const renderDialog = () => {
    if (item) {
      setInputData(item);
    }
  }

  return (
    <div style={style}>
      <DialogButton disabled={disabled} onClick={handleClickOpen} />
      <ConfirmationDialog
        title="Confirm Action"
        description="Are you sure you want to save the employee data?"
        open={openConfirm}
        setOpen={setConfirm}
        onConfirm={handleSubmit}
      />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{title} UnpaidLeave</DialogTitle>
        <form onSubmit={handleSubmit} noValidate>
          <DialogContent>
            {!isDetail ?
              <DialogContentText>
                Please complete the following form.
              </DialogContentText> : null
            }
            {errors.hrEmail && (
              <span className="error-message">{errors.hrEmail}</span>
            )}
            <TextField
              disabled={isDetail}
              required
              margin="dense"
              name="employeeNumber"
              type="text"
              value={inputData.employeeNumber}
              label="Employee ID"
              fullWidth
              onChange={handleInputChange}
            />
            {errors.employeeNumber && (
              <span className="error-message">{errors.employeeNumber}</span>
            )}
            <TextField
              disabled={isDetail}
              required
              select
              margin="dense"
              id="region"
              value={inputData.region}
              label="Region"
              fullWidth
              onChange={(c) => { handleInputChange({ target: { name: "region", value: c.target.value } }) }}
            >
              {generalSettings?.regions ?
                generalSettings?.regions.map((region) => (
                  <MenuItem key={region} value={region}>
                    {region}
                  </MenuItem>
                ))
                : null}
            </TextField>
            {errors.region && (
              <span className="error-message">{errors.region}</span>
            )}
            <TextField
              disabled={isDetail}
              required
              margin="dense"
              name="name"
              type="text"
              value={inputData.name}
              label="Name"
              fullWidth
              onChange={handleInputChange}
            />
            {errors.name && (
              <span className="error-message">{errors.name}</span>
            )}
            <TextField
              disabled={isDetail}
              required
              margin="dense"
              name="nativeName"
              type="text"
              value={inputData.nativeName}
              label="Native Name"
              fullWidth
              onChange={handleInputChange}
            />
            {errors.name && (
              <span className="error-message">{errors.nativeName}</span>
            )}
            <TextField
              disabled={isDetail}
              required
              margin="dense"
              name="email"
              type="email"
              value={inputData.email}
              label="17LIVE Email Address"
              fullWidth
              onChange={handleInputChange}
            />
            {errors.email && (
              <span className="error-message">{errors.email}</span>
            )}
            <CustomDateTimePicker
              disabled={isDetail}
              id="returnDeviceAt"
              className="custom-date-picker"
              value={inputData.returnDeviceAt}
              label="Return Device DateTime"
              onChange={(c) => { handleInputChange({ target: { name: "returnDeviceAt", value: c.format("YYYY-MM-DD HH:mm:ss") } }) }}
            />
            {errors.returnDeviceAt && (
              <span className="error-message">{errors.returnDeviceAt}</span>
            )}
            <CustomDatePicker
              disabled={isDetail}
              id="suspendStartAt"
              className="custom-date-picker"
              value={inputData.suspendStartAt}
              label="Suspend Start Date"
              onChange={(c) => { handleInputChange({ target: { name: "suspendStartAt", value: c.format("YYYY-MM-DD") } }) }}
            />
            {errors.suspendStartAt && (
              <span className="error-message">{errors.suspendStartAt}</span>
            )}
            <CustomDatePicker
              disabled={isDetail}
              id="suspendEndAt"
              className="custom-date-picker"
              value={inputData.suspendEndAt}
              label="Suspend End Date"
              onChange={(c) => { handleInputChange({ target: { name: "suspendEndAt", value: c.format("YYYY-MM-DD") } }) }}
            />
            {errors.suspendEndAt && (
              <span className="error-message">{errors.suspendEndAt}</span>
            )}
          </DialogContent>
          {!isDetail ?
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit" onClick={handleValidation}>Submit</Button>
            </DialogActions> :
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
            </DialogActions>
          }
        </form>
      </Dialog>
    </div>
  );
}

export { NEW, EDIT, DETAIL, UnpaidLeaveDialog };
