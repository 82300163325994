import { useState, useEffect } from "react"
import {
  Button,
  TextField,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Switch,
  FormGroup,
  FormControlLabel,
} from "@mui/material"
import CustomDatePicker from "../../../components/DatePicker"
import ConfirmationDialog from "../../../components/ConfirmationDialog"
import { types } from "../../../constants/type"
import { STATUSABANDON } from "../../../constants/hireStatus"
import { employeeAPI } from "../../../apis/employeeAPI"
import "./NewHireDialog.css"

const EDIT = "Edit"
const NEW = "New"
const DETAIL = "Detail"

function NewHireDialog({
  button: DialogButton,
  style,
  item,
  title,
  renderRows,
  userInfo,
  generalSettings,
}) {

  const initialState = {
    employeeNumber: '',
    nativeName: '',
    englishName: '',
    email: null,
    region: 'TW',
    type: 1,
    personlEmail: '',
    employeeType: 1,
    division: '',
    function: '',
    department: '',
    team: '',
    position: '',
    reportingManagerEmail: '',
    hiringManagerEmail: '',
    hireDate: new Date().toLocaleDateString('en-CA'),
    telNo: '',
    address: '',
    hrEmail: '',
    device: 'NA',
    deviceRemarks: null,
    additionalInfo: '',
  };

  const [errors, setErrors] = useState({});
  const [open, setOpen] = useState(false);
  const [openConfirm, setConfirm] = useState(false);
  const [inputData, setInputData] = useState(item ?? initialState);
  const [isDetail,] = useState(title === DETAIL);
  const [isEdit,] = useState(title === EDIT);

  useEffect(() => {
    renderDialog();
  }, [item])

  const handleClickOpen = () => {
    setOpen(true);
    renderDialog();
  }
  const handleClose = () => setOpen(false);
  const handleInputChange = (e) => {
    setInputData({ ...inputData, [e.target.name]: e.target.value ? e.target.value : null, hrEmail: userInfo?.email });
  }
  const validateValues = (inputValues) => {
    let errors = {};
    if (!inputValues.employeeNumber) {
      errors.employeeNumber = "Employee ID is required";
    }
    if (!inputValues.nativeName) {
      errors.nativeName = "Native Name is required";
    }
    if (!inputValues.englishName) {
      errors.englishName = "English Name is required";
    }
    if (!inputValues.email) {
      errors.email = "Email is required";
    }
    if (inputValues.email && !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(inputValues.email)) {
      errors.email = "This is the wrong pattern";
    }
    if (!inputValues.personlEmail) {
      errors.personlEmail = "Personal Email is required";
    }
    if (inputValues.personlEmail && !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(inputValues.personlEmail)) {
      errors.personlEmail = "This is the wrong pattern";
    }
    if (!inputValues.region) {
      errors.region = "Region is required";
    }
    if (!inputValues.employeeType) {
      errors.employeeType = "Type is required";
    }
    if (!inputValues.division) {
      errors.division = "Division is required";
    }
    if (!inputValues.function) {
      errors.function = "Function is required";
    }
    if (!inputValues.department) {
      errors.department = "Department is required";
    }
    if (!inputValues.team) {
      errors.team = "Team is required";
    }
    if (!inputValues.position) {
      errors.position = "Position is required";
    }
    if (!inputValues.reportingManagerEmail) {
      errors.reportingManagerEmail = "Reporting Manager Email is required";
    }
    if (inputValues.reportingManagerEmail && !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(inputValues.reportingManagerEmail)) {
      errors.reportingManagerEmail = "This is the wrong pattern";
    }
    if (!inputValues.hiringManagerEmail) {
      errors.hiringManagerEmail = "Hiring Manager Email is required";
    }
    if (inputValues.hiringManagerEmail && !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(inputValues.hiringManagerEmail)) {
      errors.hiringManagerEmail = "This is the wrong pattern";
    }
    if (!inputValues.hireDate) {
      errors.hireDate = "New Hire Date is required";
    }
    if (!inputValues.telNo) {
      errors.telNo = "Tel No. is required";
    }
    if (!inputValues.address) {
      errors.address = "Address is required";
    }
    if (!inputValues.hrEmail) {
      errors.hrEmail = "HR Email not found";
    }
    return errors;
  }
  const handleValidation = (e) => {
    e.preventDefault();
    let errors = validateValues(inputData);
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      setConfirm(true);
    }
  }
  const handleSubmit = (event) => {
    switch (title) {
      case NEW:
        employeeAPI.createNewHire(inputData)
          .then(({ id }) => {
            renderRows();
          })
        break
      case EDIT:
        employeeAPI.updateNewHire(inputData.id, inputData)
          .then(() => {
            renderRows();
          })
        break
      default:
        console.log("title not found");
    }
    setInputData(initialState);
    handleClose()
  }
  const renderDialog = () => {
    if (item) {
      setInputData(item);
    }
  }

  return (
    <div style={style}>
      <DialogButton onClick={handleClickOpen} />
      <ConfirmationDialog
        title="Confirm Action"
        description="Are you sure you want to save the employee data?"
        open={openConfirm}
        setOpen={setConfirm}
        onConfirm={handleSubmit}
      />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{title} NewHire</DialogTitle>
        <form onSubmit={handleSubmit} noValidate>
          <DialogContent>
            {!isDetail ?
              <DialogContentText>
                Please complete the following form.
              </DialogContentText> : null
            }
            {isEdit ?
              <FormGroup>
                <FormControlLabel
                  className="pink-switch-label"
                  control={
                    <Switch className="pink-switch"
                      value={inputData.hireStatus}
                      checked={inputData.hireStatus == STATUSABANDON}
                      onChange={(c) => {
                        handleInputChange({ target: { name: "hireStatus", value: c.target.checked ? STATUSABANDON : c.target.value } })
                      }}
                      name="hireStatus"
                    />
                  }
                  label="Abandon"
                />
              </FormGroup> : null
            }
            {errors.hrEmail && (
              <span className="error-message">{errors.hrEmail}</span>
            )}
            <TextField
              disabled={isDetail}
              required
              variant="outlined"
              margin="dense"
              name="employeeNumber"
              type="text"
              value={inputData.employeeNumber}
              label="Employee ID"
              fullWidth
              onChange={handleInputChange}
            />
            {errors.employeeNumber && (
              <span className="error-message">{errors.employeeNumber}</span>
            )}
            <TextField
              disabled={isDetail}
              required
              margin="dense"
              name="nativeName"
              type="text"
              value={inputData.nativeName}
              label="Native Name"
              fullWidth
              onChange={handleInputChange}
            />
            {errors.nativeName && (
              <span className="error-message">{errors.nativeName}</span>
            )}
            <TextField
              disabled={isDetail}
              required
              margin="dense"
              name="englishName"
              type="text"
              value={inputData.englishName}
              label="English Name"
              fullWidth
              onChange={handleInputChange}
            />
            {errors.englishName && (
              <span className="error-message">{errors.englishName}</span>
            )}
            <TextField
              disabled={isDetail}
              required
              margin="dense"
              name="email"
              type="email"
              value={inputData.email ?? ''}
              label="Email"
              fullWidth
              onChange={handleInputChange}
            />
            {errors.email && (
              <span className="error-message">{errors.email}</span>
            )}
            <TextField
              disabled={isDetail}
              required
              margin="dense"
              name="personlEmail"
              type="email"
              value={inputData.personlEmail}
              label="Personal Email"
              fullWidth
              onChange={handleInputChange}
            />
            {errors.personlEmail && (
              <span className="error-message">{errors.personlEmail}</span>
            )}
            <TextField
              disabled={isDetail}
              required
              select
              margin="dense"
              id="region"
              value={inputData.region}
              label="Region"
              fullWidth
              onChange={(c) => { handleInputChange({ target: { name: "region", value: c.target.value } }) }}
            >
              {generalSettings?.regions ?
                generalSettings?.regions.map((region) => (
                  <MenuItem key={region} value={region}>
                    {region}
                  </MenuItem>
                ))
                : null}
            </TextField>
            {errors.region && (
              <span className="error-message">{errors.region}</span>
            )}
            <TextField
              disabled={isDetail}
              required
              select
              margin="dense"
              id="employeeType"
              value={inputData.employeeType}
              label="Type"
              fullWidth
              onChange={(c) => { handleInputChange({ target: { name: "employeeType", value: c.target.value } }) }}
            >
              {types.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            {errors.employeeType && (
              <span className="error-message">{errors.employeeType}</span>
            )}
            <TextField
              disabled={isDetail}
              required
              margin="dense"
              name="division"
              type="text"
              value={inputData.division}
              label="Division"
              fullWidth
              onChange={handleInputChange}
            />
            {errors.division && (
              <span className="error-message">{errors.division}</span>
            )}
            <TextField
              disabled={isDetail}
              required
              margin="dense"
              name="function"
              type="text"
              value={inputData.function}
              label="Function"
              fullWidth
              onChange={handleInputChange}
            />
            {errors.function && (
              <span className="error-message">{errors.function}</span>
            )}
            <TextField
              disabled={isDetail}
              required
              margin="dense"
              name="department"
              type="text"
              value={inputData.department}
              label="Department"
              fullWidth
              onChange={handleInputChange}
            />
            {errors.department && (
              <span className="error-message">{errors.department}</span>
            )}
            <TextField
              disabled={isDetail}
              required
              margin="dense"
              name="team"
              type="text"
              value={inputData.team}
              label="Team"
              fullWidth
              onChange={handleInputChange}
            />
            {errors.team && (
              <span className="error-message">{errors.team}</span>
            )}
            <TextField
              disabled={isDetail}
              required
              margin="dense"
              name="position"
              type="text"
              value={inputData.position}
              label="Position"
              fullWidth
              onChange={handleInputChange}
            />
            {errors.position && (
              <span className="error-message">{errors.position}</span>
            )}
            <TextField
              disabled={isDetail}
              required
              margin="dense"
              name="reportingManagerEmail"
              type="email"
              value={inputData.reportingManagerEmail}
              label="Reporting Manager Email"
              fullWidth
              onChange={handleInputChange}
            />
            {errors.reportingManagerEmail && (
              <span className="error-message">{errors.reportingManagerEmail}</span>
            )}
            <TextField
              disabled={isDetail}
              required
              margin="dense"
              name="hiringManagerEmail"
              type="email"
              value={inputData.hiringManagerEmail}
              label="Hiring Manager Email"
              fullWidth
              onChange={handleInputChange}
            />
            {errors.hiringManagerEmail && (
              <span className="error-message">{errors.hiringManagerEmail}</span>
            )}
            <CustomDatePicker
              disabled={isDetail}
              required
              name="hireDate"
              className="custom-date-picker"
              value={inputData.hireDate}
              label="New Hire Date"
              fullWidth
              onChange={(c) => { handleInputChange({ target: { name: "hireDate", value: c.format("YYYY-MM-DD") } }) }}
            />
            {errors.hireDate && (
              <span className="error-message">{errors.hireDate}</span>
            )}
            <TextField
              disabled={isDetail}
              required
              margin="dense"
              name="telNo"
              type="tel"
              value={inputData.telNo}
              label="Tel No."
              fullWidth
              onChange={handleInputChange}
            />
            {errors.telNo && (
              <span className="error-message">{errors.telNo}</span>
            )}
            <TextField
              disabled={isDetail}
              required
              margin="dense"
              name="address"
              type="text"
              value={inputData.address}
              label="Address"
              fullWidth
              onChange={handleInputChange}
            />
            {errors.address && (
              <span className="error-message">{errors.address}</span>
            )}
            <TextField
              disabled={isDetail}
              required
              select
              margin="dense"
              id="device"
              value={inputData.device}
              label="Device"
              fullWidth
              onChange={(c) => { handleInputChange({ target: { name: "device", value: c.target.value } }) }}
            >
              {generalSettings?.devices ?
                generalSettings?.devices.map((device) => (
                  <MenuItem key={device} value={device}>
                    {device}
                  </MenuItem>
                ))
                : null}
            </TextField>
            {errors.device && (
              <span className="error-message">{errors.device}</span>
            )}
            <TextField
              disabled={isDetail}
              margin="dense"
              name="deviceRemarks"
              type="text"
              value={inputData.deviceRemarks ?? ''}
              label="Device Remarks"
              fullWidth
              onChange={handleInputChange}
            />
            <TextField
              disabled={isDetail}
              margin="dense"
              name="additionalInfo"
              type="text"
              value={inputData.additionalInfo}
              label="Additional Info"
              multiline
              rows="5"
              fullWidth
              onChange={handleInputChange}
            />
          </DialogContent>
          {
            !isDetail ?
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button type="submit" onClick={handleValidation}>Submit</Button>
              </DialogActions> :
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
              </DialogActions>
          }
        </form>
      </Dialog >
    </div>
  );
}

export { EDIT, NEW, DETAIL, NewHireDialog };
