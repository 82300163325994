import { useNavigate } from "react-router-dom";
import { useOktaAuth } from '@okta/okta-react';
import { LOGIN_PAGE } from '../routes';

const WithOktaAuth = () => {
  const navigate = useNavigate();
  const { oktaAuth, authState } = useOktaAuth();

  if (!authState?.isAuthenticated) {
    navigate(LOGIN_PAGE);
  }

  return { oktaAuth, authState };
};

export default WithOktaAuth;