const STATUSALL = 0;
const STATUSPENDING = 1;
const STATUSABANDON = 2;
const STATUSCOMPLETED = 3;

const statuses = [
  {
    label: 'All',
    value: STATUSALL,
  },
  {
    label: 'Pending',
    value: STATUSPENDING,
  },
  {
    label: 'Abandon',
    value: STATUSABANDON,
  },
  {
    label: 'Completed',
    value: STATUSCOMPLETED,
  },
];

const statusesObj = {}
statuses.forEach((status) => {
  statusesObj[status.value] = status.label
})

export { STATUSALL, STATUSPENDING, STATUSABANDON, STATUSCOMPLETED, statuses, statusesObj }