import { useState, useEffect } from "react"
import EmployeeLayout from "../../../layouts/employee/EmployeeLayout";
import MDButton from "../../../components/MDButton";
import ConfirmationDialog from "../../../components/ConfirmationDialog";
import WithOktaAuth from "../../../components/WithOktaAuth";
import { NewButton, EditButton, DetailButton } from "../../../components/Buttons";
import { EDIT, NEW, DETAIL, NewHireDialog } from "../../../views/Dialogs/EmployeeDialog/NewHireDialog";
import { statusesObj } from "../../../constants/hireStatus";
import { typesObj } from "../../../constants/type";
import { employeeAPI } from "../../../apis/employeeAPI";

const NewHire = () => {
  const { oktaAuth, authState } = WithOktaAuth();
  const [renderLock, setRenderLock] = useState(false); // avoid pagination error
  const [userInfo, setUserInfo] = useState(null);
  const [generalSettings, setGeneralSettings] = useState({});
  const [data, setData] = useState({});
  const [openConfirm, setConfirm] = useState(false);
  const [deleteID, setDeleteID] = useState(null);
  const [filterData, setFilter] = useState({
    date_filter: new Date().toLocaleDateString('en-CA'),
    status_filter: null,
    employee_id_filter: null,
  });
  const [page, setPage] = useState({
    page: 1,
    pageSize: 10
  });

  useEffect(() => {
    if (authState?.isAuthenticated) {
      oktaAuth.getUser().then((info) => {
        setUserInfo(info);
        renderRows();
      }).catch((err) => {
        console.error('error fetching user info:', err);
      })

      employeeAPI.getGeneralSettings().then((res) => {
        setGeneralSettings(res.data);
      })
    }
  }, [authState]);

  useEffect(() => {
    renderRows();
  }, [
    filterData,
    page,
    generalSettings,
    userInfo,
  ]);

  const handleChangeFilter = (filterData) => {
    setFilter(filterData);
  }

  const handleChangePage = (page) => {
    setPage(page);
  }

  const handleDelete = () => {
    employeeAPI.deleteNewHire(
      {
        id: deleteID,
        hrEmail: userInfo.email,
      }
    ).then(() => {
      renderRows();
    });
  }

  const renderRows = () => {
    if (renderLock) {
      return;
    }
    setRenderLock(true);
    employeeAPI.listNewHire(
      {
        startDate: filterData.date_filter,
        endDate: filterData.date_filter,
        status: filterData.status_filter,
        employeeNumber: filterData.employee_id_filter,
        ...page
      }
    ).then((res) => {
      if (!generalSettings || !userInfo) {
        return;
      }

      res.data.data = res.data.data.map((item) => {
        let newItem = JSON.parse(JSON.stringify(item));
        newItem.employeeType = typesObj[newItem.employeeType];
        newItem.hireStatus = statusesObj[newItem.hireStatus];
        return {
          ...newItem,
          employeeNumber: (
            <NewHireDialog
              button={DetailButton(item.employeeNumber)}
              item={item}
              title={DETAIL}
              renderRows={renderRows}
              userInfo={userInfo}
              generalSettings={generalSettings}
            />
          ),
          action: (
            <div style={{ display: 'flex' }}>
              <NewHireDialog
                style={{ flex: '50%' }}
                button={EditButton}
                item={item}
                title={EDIT}
                renderRows={renderRows}
                userInfo={userInfo}
                generalSettings={generalSettings}
              />
              <MDButton style={{ flex: '50%' }} onClick={() => { setConfirm(true); setDeleteID(item.id) }} variant="caption" color="text" fontWeight="medium">Delete</MDButton>
            </div>
          )
        };
      })
      setData(res.data);
    })
    setRenderLock(false);
  }

  const columns = [
    { Header: "Employee ID", accessor: "employeeNumber", align: "left" },
    { Header: "Status", accessor: "hireStatus", align: "left" },
    { Header: "Native Name", accessor: "nativeName", align: "left" },
    { Header: "English Name", accessor: "englishName", align: "left" },
    { Header: "Email", accessor: "email", align: "left" },
    { Header: "Type", accessor: "employeeType", align: "left" },
    { Header: "Division", accessor: "division", align: "left" },
    { Header: "Position", accessor: "position", align: "left" },
    { Header: "Hire Date", accessor: "hireDate", align: "left" },
    { Header: "Action", accessor: "action", align: "center" },
  ];

  if (!authState?.isAuthenticated) {
    return null;
  }

  return (
    <EmployeeLayout
      onFilterChange={handleChangeFilter}
      onPageChange={handleChangePage}
      data={data}
      columns={columns}
    >
      <NewHireDialog
        button={NewButton}
        title={NEW}
        renderRows={renderRows}
        userInfo={userInfo}
        generalSettings={generalSettings}
      />
      <ConfirmationDialog
        title="Confirm Action"
        description="Are you sure you want to delete the employee?"
        open={openConfirm}
        setOpen={setConfirm}
        onConfirm={handleDelete}
      ></ConfirmationDialog>
    </EmployeeLayout>
  );
}

export default NewHire;
