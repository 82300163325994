import React, { useEffect } from "react";
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { Container } from "semantic-ui-react";
import { Security, LoginCallback } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";

import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import config from "./config";
import Home from './pages/employee/Home';

import {
  useMaterialUIController,
} from "./context";

import theme from "./assets/theme";
import Sidenav from "./views/Sidenav";
import routes from "./routes";

const oktaAuth = new OktaAuth(config.oidc);

const App = () => {
  const [controller] = useMaterialUIController();
  const {
    direction,
    sidenavColor,
  } = controller;
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    navigate(toRelativeUrl(originalUri || "/", window.location.origin));
  };

  const triggerLogin = () => {
    oktaAuth.signInWithRedirect();
  };

  const customAuthHandler = async () => {
    const previousAuthState = oktaAuth.authStateManager.getPreviousAuthState();
    if (!previousAuthState || !previousAuthState.isAuthenticated) {
      triggerLogin();
    }
  };

  const [setCorsErrorModalOpen] = React.useState(null);
  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={route.component}
            key={route.key}
          />
        );
      }
      return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <Security
        oktaAuth={oktaAuth}
        onAuthRequired={customAuthHandler}
        restoreOriginalUri={restoreOriginalUri}
      >
        <CssBaseline />
        <>
          <Sidenav
            setCorsErrorModalOpen={setCorsErrorModalOpen}
            color={sidenavColor}
            brandName="Internal system"
            routes={routes}
          />
        </>
        <Container text style={{ marginTop: "0em" }}>
          <Routes>
            {getRoutes(routes)}
            <Route path="/" exact element={<Home />} />
            <Route path="/login/callback" element={<LoginCallback />} />
            <Route path="*" element={<Navigate to='/' />} />
          </Routes>
        </Container>
      </Security>
    </ThemeProvider>
  );
};

export default App;
