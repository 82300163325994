import { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'moment-timezone'
import WithOktaAuth from "../../../components/WithOktaAuth";
import MDBox from "../../../components/MDBox";
import DashboardLayout from "../../../views/LayoutContainers/DashboardLayout";
import { employeeAPI } from "../../../apis/employeeAPI";
import "react-big-calendar/lib/css/react-big-calendar.css";
import './Schedule.css'

const localizer = momentLocalizer(moment)
const defaultBackground = '#344767';
const Schedule = () => {
  const { authState } = WithOktaAuth();
  const [eventsData, setEventsData] = useState([]);

  useEffect(() => {
    if (authState?.isAuthenticated) {
      renderEvents();
    }
  }, [authState]);

  const renderEvents = () => {
    employeeAPI.getCalendarList().then((res) => {
      var events = [];
      if (res?.data?.newHireList) {
        res.data.newHireList.forEach((event) => {
          events.push({
            id: `newHire_${event.id}`,
            title: `${event.nativeName}（${event.englishName}）`,
            start: new Date(event.hireDate),
            end: new Date(event.hireDate),
            allDay: true,
            backgroundColor: '#fdba4c',
          });
        });
      }
      if (res?.data?.terminationList) {
        res.data.terminationList.forEach((event) => {
          events.push({
            id: `termination_${event.id}`,
            title: event.name,
            start: new Date(event.terminationAt),
            end: new Date(event.terminationAt),
            allDay: true,
            backgroundColor: '#007FFF',
          });
        });
      }
      if (res?.data?.unpaidLeaveList) {
        res.data.unpaidLeaveList.forEach((event) => {
          events.push({
            id: `unpaidLeaveList_${event.id}`,
            title: event.name,
            start: new Date(event.suspendStartAt),
            end: new Date(event.suspendEndAt),
            allDay: true,
            backgroundColor: '#A9A9A9',
          });
        });
      }
      if (res?.data?.transitionList) {
        res.data.transitionList.forEach((event) => {
          events.push({
            id: `transitionList_${event.id}`,
            title: event.name,
            start: new Date(event.transitionAt),
            end: new Date(event.transitionAt),
            allDay: true,
            backgroundColor: '#4CAF50',
          });
        });
      }
      setEventsData(events);
    })
  }

  const eventStyleGetter = (event) => {
    const eventBackgroundColor = event.backgroundColor ?? defaultBackground;
    return {
      style: {
        backgroundColor: eventBackgroundColor,
        fontSize: 15,
        fontWeight: 'bold',
      }
    }
  }

  if (!authState?.isAuthenticated) {
    return null;
  }

  return (
    <DashboardLayout>
      <MDBox>
        <Calendar
          localizer={localizer}
          events={eventsData}
          defaultDate={new Date()}
          defaultView="month"
          startAccessor="start"
          endAccessor="end"
          className="calendar"
          eventPropGetter={eventStyleGetter}
        />
        <div className="schedule-container">
          <div className="circle new-hire-circle">
            <span className="mark-text">New Hire</span>
          </div>
          <div className="circle termination-circle">
            <span className="mark-text">Termination</span>
          </div>
          <div className="circle unpaid-leave-circle">
            <span className="mark-text">Unpaid Leave</span>
          </div>
          <div className="circle transition-circle">
            <span className="mark-text">Transition</span>
          </div>
        </div>
      </MDBox >
    </DashboardLayout >
  )
}

export default Schedule;
