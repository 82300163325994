import AddIcon from '@mui/icons-material/Add';
import Link from '@mui/material/Link';
import MDButton from "./MDButton";

const NewButton = ({ onClick }) => {
  return (
    <MDButton onClick={onClick} variant="gradient" color="dark">
      <AddIcon sx={{ fontWeight: "bold" }} />
      &nbsp;new
    </MDButton>
  );
}

const EditButton = ({ onClick, disabled }) => {
  return (
    <MDButton disabled={disabled} component="a" href="#" onClick={onClick} variant="caption" color="text" fontWeight="medium">
      Edit
    </MDButton>
  );
}

const DetailButton = (id) => {
  return ({ onClick }) => {
    return (
      <Link href="#" fontWeight="medium" onClick={onClick}>
        {id}
      </Link>
    );
  };
}

export { NewButton, EditButton, DetailButton };

