import { OKTA_CLIENT_ID, OKTA_ISSUER } from './constants';
const OKTA_TESTING_DISABLEHTTPSCHECK = false;
const BASENAME = 'InternalSystem';
const REDIRECT_URI = `${window.location.origin}/login/callback`;
const USE_INTERACTION_CODE = false;
// if (process.env.USE_INTERACTION_CODE === 'true') {
//   USE_INTERACTION_CODE = true;
// }

// eslint-disable-next-line
const issuer = OKTA_ISSUER,
  clientId = OKTA_CLIENT_ID,
  redirectUri = REDIRECT_URI,
  scopes = ['openid', 'profile', 'email'],
  useInteractionCode = USE_INTERACTION_CODE;

export default {
  oidc: {
    clientId,
    issuer,
    redirectUri,
    scopes,
    pkce: true,
    disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
    useInteractionCode,
  },
  widget: {
    /**
    * Note: when using the Sign-In Widget for an OIDC flow, it still
    * needs to be configured with the base URL for your Okta Org. Here
    * we derive it from the given issuer for convenience.
    */
    baseUrl: issuer,
    clientId,
    redirectUri,
    proxyIdxResponse: false,
    stateToken: "",
    logo: `${process.env.PUBLIC_URL}/logo.png`,
    i18n: {
      en: {
        "primaryauth.title": "Sign in to React & Company",
      },
    },
    authParams: {
      // To avoid redirect do not set "pkce" or "display" here. OKTA-335945
      issuer,
      scopes,
    },
    useInteractionCodeFlow: useInteractionCode, // Set to true, if your org is OIE enabled
  },
  resourceServer: {
    messagesUrl: 'http://localhost:8000/api/messages',
  },
  app: {
    basename: BASENAME,
  },
};
