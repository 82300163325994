// @mui icons
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import PersonRemoveAlt1Icon from '@mui/icons-material/PersonRemoveAlt1';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';

import NewHire from './pages/employee/new-hire/NewHire';
import Termination from './pages/employee/termination/Termination';
import Schedule from './pages/employee/schedule/Schedule';
import UnpaidLeave from './pages/employee/unpaid-leave/UnpaidLeave';
import Transition from './pages/employee/transition/Transition';

export const SCHEDULE_PAGE = "/employee/schedule"
export const NEW_HIRE_PAGE = "/employee/new-hire"
export const TERMINATION_PAGE = "/employee/termination"
export const UNPAIDLEAVE_PAGE = "/employee/unpaid-leave"
export const TRANSITION_PAGE = "/employee/transition"
export const LOGIN_PAGE = "/"

const routes = [
  {
    type: "collapse",
    name: "Schedule",
    key: "schedule",
    icon: <CalendarMonthIcon fontSize="small"></CalendarMonthIcon>,
    route: SCHEDULE_PAGE,
    component: <Schedule />,
  },
  {
    type: "collapse",
    name: "New Hire",
    key: "new-hire",
    icon: <PersonAddAlt1Icon fontSize="small"></PersonAddAlt1Icon>,
    route: NEW_HIRE_PAGE,
    component: <NewHire />,
  },
  {
    type: "collapse",
    name: "Termination",
    key: "termination",
    icon: <PersonRemoveAlt1Icon fontSize="small"></PersonRemoveAlt1Icon>,
    route: TERMINATION_PAGE,
    component: <Termination />,
  },
  {
    type: "collapse",
    name: "Unpaid Leave",
    key: "unpaid-leave",
    icon: <NoAccountsIcon fontSize="small"></NoAccountsIcon>,
    route: UNPAIDLEAVE_PAGE,
    component: <UnpaidLeave />,
  },
  {
    type: "collapse",
    name: "Transition",
    key: "transition",
    icon: <TransferWithinAStationIcon fontSize="small"></TransferWithinAStationIcon>,
    route: TRANSITION_PAGE,
    component: <Transition />,
  }
];

export default routes;
