import api from "./configs/axiosConfigs"
import { defineCancelApiObject } from "./configs/axiosUtils"

export const employeeAPI = {
  getGeneralSettings: async function (data, cancel = false) {
    return await api.get(
      '/employee/general/settings',
      data,
      { signal: cancel ? cancelApiObject[this.getGeneralSettings.name].handleRequestCancellation().signal : undefined }
    )
  },
  getCalendarList: async function (data, cancel = false) {
    return await api.get(
      '/employee/calendar/list',
      data,
      { signal: cancel ? cancelApiObject[this.getCalendarList.name].handleRequestCancellation().signal : undefined }
    )
  },
  createTermination: async function (data, cancel = false) {
    return await api.post(
      '/employee/termination/new',
      data,
      { signal: cancel ? cancelApiObject[this.createTermination.name].handleRequestCancellation().signal : undefined }
    )
  },
  listTermination: async function (data, cancel = false) {
    return await api.get(
      '/employee/termination/list',
      { params: data },
      { signal: cancel ? cancelApiObject[this.createTermination.name].handleRequestCancellation().signal : undefined }
    )
  },
  updateTermination: async function (id, data, cancel = false) {
    return await api.patch(
      `/employee/termination/${id}`,
      data,
      { signal: cancel ? cancelApiObject[this.updateTermination.name].handleRequestCancellation().signal : undefined },
    )
  },
  deleteTermination: async function (data, cancel = false) {
    return await api.delete(
      '/employee/termination/',
      { data: data },
      { signal: cancel ? cancelApiObject[this.deleteTermination.name].handleRequestCancellation().signal : undefined },
    )
  },
  createNewHire: async function (data, cancel = false) {
    return await api.post(
      '/employee/newHire/new',
      data,
      { signal: cancel ? cancelApiObject[this.createNewHire.name].handleRequestCancellation().signal : undefined }
    )
  },
  listNewHire: async function (data, cancel = false) {
    return await api.get(
      '/employee/newHire/list',
      { params: data },
      { signal: cancel ? cancelApiObject[this.listNewHire.name].handleRequestCancellation().signal : undefined }
    )
  },
  updateNewHire: async function (id, data, cancel = false) {
    return await api.patch(
      `/employee/newHire/${id}`,
      data,
      { signal: cancel ? cancelApiObject[this.updateNewHire.name].handleRequestCancellation().signal : undefined },
    )
  },
  deleteNewHire: async function (data, cancel = false) {
    return await api.delete(
      '/employee/newHire/',
      { data: data },
      { signal: cancel ? cancelApiObject[this.deleteNewHire.name].handleRequestCancellation().signal : undefined },
    )
  },
  createUnpaidLeave: async function (data, cancel = false) {
    return await api.post(
      '/employee/unpaidLeave/new',
      data,
      { signal: cancel ? cancelApiObject[this.createNewHire.name].handleRequestCancellation().signal : undefined }
    )
  },
  listUnpaidLeave: async function (data, cancel = false) {
    return await api.get(
      '/employee/unpaidLeave/list',
      { params: data },
      { signal: cancel ? cancelApiObject[this.listUnpaidLeave.name].handleRequestCancellation().signal : undefined }
    )
  },
  updateUnpaidLeave: async function (id, data, cancel = false) {
    return await api.patch(
      `/employee/unpaidLeave/${id}`,
      data,
      { signal: cancel ? cancelApiObject[this.updateUnpaidLeave.name].handleRequestCancellation().signal : undefined },
    )
  },
  deleteUnpaidLeave: async function (data, cancel = false) {
    return await api.delete(
      '/employee/unpaidLeave/',
      { data: data },
      { signal: cancel ? cancelApiObject[this.deleteUnpaidLeave.name].handleRequestCancellation().signal : undefined },
    )
  },
  createTransition: async function (data, cancel = false) {
    return await api.post(
      '/employee/transition/new',
      data,
      { signal: cancel ? cancelApiObject[this.createTransition.name].handleRequestCancellation().signal : undefined }
    )
  },
  listTransition: async function (data, cancel = false) {
    return await api.get(
      '/employee/transition/list',
      { params: data },
      { signal: cancel ? cancelApiObject[this.listTransition.name].handleRequestCancellation().signal : undefined }
    )
  },
  updateTransition: async function (id, data, cancel = false) {
    return await api.patch(
      `/employee/transition/${id}`,
      data,
      { signal: cancel ? cancelApiObject[this.updateTransition.name].handleRequestCancellation().signal : undefined },
    )
  },
  deleteTransition: async function (data, cancel = false) {
    return await api.delete(
      '/employee/transition/',
      { data: data },
      { signal: cancel ? cancelApiObject[this.deleteTransition.name].handleRequestCancellation().signal : undefined },
    )
  },
}

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(employeeAPI)