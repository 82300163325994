import moment from "moment";
import { useState, useEffect } from "react";
import {
  Button,
  TextField,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material"
import CustomDatePicker from "../../../components/DatePicker";
import CustomDateTimePicker from "../../../components/DateTimePicker";
import ConfirmationDialog from "../../../components/ConfirmationDialog";
import { types } from "../../../constants/type";
import { employeeAPI } from "../../../apis/employeeAPI";
import "./TerminationDialog.css";


const EDIT = "Edit"
const NEW = "New"
const DETAIL = "Detail"

function TerminationDialog({
  disabled,
  button: DialogButton,
  style,
  item,
  title,
  renderRows,
  userInfo,
  generalSettings,
}) {
  const initialState = {
    region: 'TW',
    email: '',
    successorEmail: '',
    terminationAt: moment().format('YYYY-MM-DD'),
    returnDeviceAt: moment().format('YYYY-MM-DD HH:mm:ss'),
    additionalInfo: '',
    employeeNumber: '',
    name: '',
    successorGoogleDriveEmail: '',
    employeeType: 1,
    hrEmail: '',
  };

  const [errors, setErrors] = useState({});
  const [open, setOpen] = useState(false);
  const [openConfirm, setConfirm] = useState(false);
  const [inputData, setInputData] = useState(item ?? initialState);
  const [isDetail,] = useState(title === DETAIL);

  useEffect(() => {
    renderDialog();
  }, [item])

  const handleClickOpen = () => {
    setOpen(true);
    renderDialog();
  }
  const handleClose = () => setOpen(false);
  const handleInputChange = (e) => {
    setInputData({ ...inputData, [e.target.name]: e.target.value, hrEmail: userInfo?.email });
  }
  const validateValues = (inputValues) => {
    let errors = {};
    if (!inputValues.employeeNumber) {
      errors.employeeNumber = "Employee ID is required";
    }
    if (!inputValues.region) {
      errors.region = "Region is required";
    }
    if (!inputValues.employeeType) {
      errors.employeeType = "Type is required";
    }
    if (!inputValues.name) {
      errors.name = "Name is required";
    }
    if (!inputValues.email) {
      errors.email = "Email is required";
    }
    if (inputValues.email && !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(inputValues.email)) {
      errors.email = "This is the wrong pattern";
    }
    if (!inputValues.successorEmail) {
      errors.successorEmail = "Succesor's Email Address is required";
    }
    if (inputValues.successorEmail && !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(inputValues.successorEmail)) {
      errors.successorEmail = "This is the wrong pattern";
    }
    if (!inputValues.successorGoogleDriveEmail) {
      errors.successorGoogleDriveEmail = "Google Drive Succesor's Email Address is required";
    }
    if (inputValues.successorGoogleDriveEmail && !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(inputValues.successorGoogleDriveEmail)) {
      errors.successorGoogleDriveEmail = "This is the wrong pattern";
    }
    if (!inputValues.terminationAt) {
      errors.terminationAt = "Termination Date is required";
    }
    if (!inputValues.returnDeviceAt) {
      errors.returnDeviceAt = "Return Device DateTime is required";
    }
    if (!inputValues.hrEmail) {
      errors.hrEmail = "HR Email not found";
    }
    return errors;
  }
  const handleValidation = (e) => {
    e.preventDefault();
    let errors = validateValues(inputData);
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      setConfirm(true);
    }
  }
  const handleSubmit = () => {
    switch (title) {
      case NEW:
        employeeAPI.createTermination(inputData)
          .then(({ id }) => {
            renderRows();
          })
        break
      case EDIT:
        employeeAPI.updateTermination(inputData.id, inputData)
          .then(() => {
            renderRows();
          })
        break
      default:
        console.log("title not found");
    }
    setInputData(initialState);
    handleClose()
  }
  const renderDialog = () => {
    if (item) {
      setInputData(item);
    }
  }

  return (
    <div style={style}>
      <DialogButton disabled={disabled} onClick={handleClickOpen} />
      <ConfirmationDialog
        title="Confirm Action"
        description="Are you sure you want to save the employee data?"
        open={openConfirm}
        setOpen={setConfirm}
        onConfirm={handleSubmit}
      />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{title} Termination</DialogTitle>
        <form onSubmit={handleSubmit} noValidate>
          <DialogContent>
            {!isDetail ?
              <DialogContentText>
                Please complete the following form.
              </DialogContentText> : null
            }
            {errors.hrEmail && (
              <span className="error-message">{errors.hrEmail}</span>
            )}
            <TextField
              disabled={isDetail}
              required
              margin="dense"
              name="employeeNumber"
              type="text"
              value={inputData.employeeNumber}
              label="Employee ID"
              fullWidth
              onChange={handleInputChange}
            />
            {errors.employeeNumber && (
              <span className="error-message">{errors.employeeNumber}</span>
            )}
            <TextField
              disabled={isDetail}
              required
              select
              margin="dense"
              id="region"
              value={inputData.region}
              label="Region"
              fullWidth
              onChange={(c) => { handleInputChange({ target: { name: "region", value: c.target.value } }) }}
            >
              {generalSettings?.regions ?
                generalSettings?.regions.map((region) => (
                  <MenuItem key={region} value={region}>
                    {region}
                  </MenuItem>
                ))
                : null}
            </TextField>
            {errors.region && (
              <span className="error-message">{errors.region}</span>
            )}
            <TextField
              disabled={isDetail}
              select
              required
              margin="dense"
              id="type"
              value={inputData.employeeType}
              label="Type"
              fullWidth
              onChange={(c) => { handleInputChange({ target: { name: "employeeType", value: c.target.value } }) }}
            >
              {types.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            {errors.type && (
              <span className="error-message">{errors.type}</span>
            )}
            <TextField
              disabled={isDetail}
              required
              margin="dense"
              name="name"
              type="text"
              value={inputData.name}
              label="Name"
              fullWidth
              onChange={handleInputChange}
            />
            {errors.name && (
              <span className="error-message">{errors.name}</span>
            )}
            <TextField
              disabled={isDetail}
              required
              margin="dense"
              name="email"
              type="email"
              value={inputData.email}
              label="17LIVE Email Address"
              fullWidth
              onChange={handleInputChange}
            />
            {errors.email && (
              <span className="error-message">{errors.email}</span>
            )}
            <TextField
              disabled={isDetail}
              required
              margin="dense"
              name="successorEmail"
              type="email"
              value={inputData.successorEmail}
              label="Succesor's Email Address"
              fullWidth
              onChange={handleInputChange}
            />
            {errors.successorEmail && (
              <span className="error-message">{errors.successorEmail}</span>
            )}
            <TextField
              disabled={isDetail}
              required
              margin="dense"
              name="successorGoogleDriveEmail"
              type="email"
              value={inputData.successorGoogleDriveEmail}
              label="Google Drive Succesor's Email Address"
              fullWidth
              onChange={handleInputChange}
            />
            {errors.successorGoogleDriveEmail && (
              <span className="error-message">{errors.successorGoogleDriveEmail}</span>
            )}
            <CustomDatePicker
              disabled={isDetail}
              id="terminationAt"
              className="custom-date-picker"
              value={inputData.terminationAt}
              label="Termination Date"
              onChange={(c) => { handleInputChange({ target: { name: "terminationAt", value: c.format("YYYY-MM-DD") } }) }}
            />
            {errors.terminationAt && (
              <span className="error-message">{errors.terminationAt}</span>
            )}
            <CustomDateTimePicker
              disabled={isDetail}
              id="returnDeviceAt"
              className="custom-date-picker"
              value={inputData.returnDeviceAt}
              label="Return Device DateTime"
              onChange={(c) => { handleInputChange({ target: { name: "returnDeviceAt", value: c.format("YYYY-MM-DD HH:mm:ss") } }) }}
            />
            {errors.returnDeviceAt && (
              <span className="error-message">{errors.returnDeviceAt}</span>
            )}
            <TextField
              disabled={isDetail}
              margin="dense"
              name="additionalInfo"
              type="text"
              value={inputData.additionalInfo}
              label="Additional Info"
              multiline
              rows="5"
              fullWidth
              onChange={handleInputChange}
            />
          </DialogContent>
          {!isDetail ?
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit" onClick={handleValidation}>Submit</Button>
            </DialogActions> :
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
            </DialogActions>
          }
        </form>
      </Dialog>
    </div>
  );
}

export { NEW, EDIT, DETAIL, TerminationDialog };
