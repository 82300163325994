import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router
} from 'react-router-dom';
import App from './App';

import { MaterialUIControllerProvider } from './context';

import './index.css';
// import reportWebVitals from './reportWebVitals';

/* eslint-disable react/jsx-filename-extension */
ReactDOM.render(
  <Router>
    <MaterialUIControllerProvider>
      <App />
    </MaterialUIControllerProvider>
  </Router>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();